<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            name="vendorCd"
            label="수급업체"
            v-model="searchParam.vendorCd"
          ></c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="VENDOR_EVALUATION_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorEvaluationKindCd"
            label="평가구분"
            v-model="searchParam.vendorEvaluationKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="EVALUATION_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="evaluationGradeCd"
            label="평가등급"
            v-model="searchParam.evaluationGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="evalTerms"
            v-model="searchParam.evalTerms"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 수급업체 평가 목록 -->
    <c-table
      ref="table"
      title="LBL0003004"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 등록 -->
            <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="linkClick()" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-assess',
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        vendorEvaluationKindCd: null,
        evaluationGradeCd: null,
        evalTerms: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            // 수급업체
            label: 'LBL0003000',
            style: 'width:200px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'vendorEvaluationKindName',
            field: 'vendorEvaluationKindName',
            // 평가구분
            label: 'LBL0003001',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 상태
            label: 'LBL0003005',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            // 평가점수
            label: 'LBL0003006',
            child: [
              {
                name: 'totalScore',
                field: 'totalScore',
                style: 'width:100px',
                // 총점
                label: 'LBL0003007',
                align: 'center',
                sortable: true,
              },
              {
                name: 'score',
                field: 'score',
                style: 'width:100px',
                // 평점
                label: 'LBL0003008',
                align: 'center',
                sortable: true,
              },
              {
                name: 'evaluationGradeName',
                field: 'evaluationGradeName',
                style: 'width:100px',
                // 등급
                label: 'LBL0003009',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            // 평가기간
            label: 'LBL0003003',
            style: 'width:200px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            // 평가결과 종합
            label: 'LBL0003010',
            style: 'width:300px',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.cim.vendor.evaluation.result.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "수급업체 평가 상세"; 
      this.popupOptions.param = {
        mdmVendorEvaluationId: row ? row.mdmVendorEvaluationId : '',
        vendorCd: row ? row.vendorCd : '',
      };
      this.popupOptions.target = () => import(`${"./vendorAssessDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
